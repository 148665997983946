.card {
  display: flex;
  font-size: 16px;
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 5px;
  background-color: #F9F9F9;
}

.card-title {
  font-weight: bold;
}

.card-authors {
  font-size: 14px;
}

.card-image {
  width: 96px;
  min-width: 96px;
  height: auto;
  margin-right: 16px;
}

.card-date {
  font-size: 12px;
  color: #666666;
}

.card-button {
  margin-left: auto;
  align-self: center;
}

@media screen and (max-width: 500px) {
  .card {
    flex-direction: column;
    align-items: center;
  }

  .card-info {
    width: 54%;
  }

  .card-image {
    margin-bottom: 8px;
  }

  .card-button {
    margin: auto;
    margin-top: 16px;
    align-self: center;
  }
}