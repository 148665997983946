.landing-container {
  background: white;
  padding-top: 24px;
}

.spotlight {
  margin: auto;
  width: 85%;
  height: 450px;
  background-image: url('../../../public/images/nova-cover.jpeg');
  background-origin: content-box;
  background-size: cover;
  background-position: right;
  position: relative;
}

.lower-container {
  display: flex;
  width: 85%;
  margin: auto;
}

.spotlight2 {
  background-image: url('../../../public/images/Excession-Youll.webp');
  height: 240px;
  flex-basis: 60%;
}

.spotlight3 {
  background-image: url('../../../public/images/wildseed.jpeg');
  background-position: center;
  height: 240px;
  flex-basis: 40%;
}

.spotlight:hover {
  cursor: pointer;
}

.spotlight-text {
  position: absolute;
  color: rgb(233, 239, 241);
  text-shadow: 2px 2px black;
  font-weight: bold;
  bottom: 8px;
  left: 8px;
}

@media screen and (max-width: 600px) {
  
  .landing-container {
    padding-top: 0;
  }
  
  .spotlight {
    width: 100%;
    height: 200px;
  }
  
  .lower-container {
    display: block;
    width: 100%;
  }
}