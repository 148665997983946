.list-img {
  width: 100%;
  height: auto;
  margin: auto;
}

.list-heading {
  padding: 24px;
}

.list-description {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
}

