.to-read-button {
  margin-bottom: 8px;
  border: none;
  outline:none;
  border-radius: 6px;
  color: white;
  background-color: rgb(51, 118, 205);
  height: 32px;
  min-width: 172px;
  text-align: center;
  padding: 8px;
  align-self: center;
  display: flex;
  align-items: center;
}

.to-read-button:hover {
  cursor: pointer;
}

.add-icon {
  height: 20px;
  width: 20px;
  filter: invert(1);
}

.action {
  margin: 0 auto 0 auto;
}