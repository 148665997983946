html {
  font-family: Roboto, Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  max-width: 1200px;
  min-height: 100vh;
  margin: auto;
  color: rgb(37, 91, 112);
  /* color: black; */
  background-color: rgb(233, 239, 241);
}

main {
  background-color: white;
  min-height: 100vh;
  padding-bottom: 80px;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
  color: inherit;
}

#root, #__next {
  isolation: isolate;
}
