.search--form {
  width: 100%
}

.search--container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid rgb(37, 91, 112);
}

.search--input {
  width: 100%;
  padding-left: 16px;
  height: 32px;
  border:  none;
  outline: none;
}

.search--button {
  position: absolute;
  right: 16px;
  height: 20px;
  width: 20px;
  background-image: url('../../../public/images/search.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
  border: none;
}

.search--button:hover {
  cursor: pointer;
}