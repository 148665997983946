.book {
  padding-top: 16px;
}

.book-container {
  margin: 16px;
  padding: 16px;
  background-color: #F9F9F9;
  border-radius: 10px;
}

.secondary-detail {
    font-size: 12px;
    color: #666666;
}

.book-image {
  height: auto;
  width: 112px;
  margin: auto;
  margin-top: 18px;
  margin-bottom: 18px;
}

.book-title {
  font-weight: bold;
}

.book-info {
  text-align: left;
  width: 60%;
  margin: auto;
}

.book-description {
  padding: 24px;
}

.book-button {
  margin: auto;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 500px) {
  .book-container {
    display: flex;
    padding: 24px;
    justify-content: center;
    width: 60%;
    margin: auto;
  }  
  
  .book-info {
    width: auto;
    margin: 0;
  }

  .book-image {
    margin: 0;
    height: 174px;
    width: auto;
    margin-right:  24px;
  }

  .book-button {
    display: block;
  }
}