.nav-star-pit {
  padding: 16px;
  display: flex;
  align-items: flex-end;
  color: rgb(233, 239, 241);
  background-color: rgb(37, 91, 112);
}

.dropdown {
  min-width: 140px;
  margin-left: auto;
  position: relative;
  display: inline-block;
}


.dropdown-button {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 4px;
}

.dropdown-button:hover {
  cursor: pointer;
}

.user-pic {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

.dropdown-content {
  min-width: 100%;
  display: none;
  position: absolute;
  background-color: white;
  color: rgb(37, 91, 112);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content p {
  padding: 4px 6px;
}

.dropdown:hover .dropdown-content {
  display: block;
  cursor: pointer;
}

.dropdown-content p:hover {background-color: #ddd;}

.login {
  margin-left: auto;
  color: inherit;
  background-color: inherit;
  border: none;
}

.login:hover {
  cursor: pointer;
}